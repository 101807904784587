"use client";

import { Box, FlexProps, Grid, Heading, HStack } from "@biblioteksentralen/react";
import { colors, sortAlphabetical } from "@biblioteksentralen/utils";
import { getSiteUrl } from "@libry-content/common";
import { Site } from "@libry-content/types";
import { useTranslation } from "../utils/hooks/useTranslation";
import { SanityImagePreservingAspectRatio } from "./images/SanityImagePreservingAspectRatio";

type Props = {
  sites?: Pick<Site, "_id" | "domain" | "municipality" | "name" | "_type">[];
} & FlexProps;

export const LinksToAllSites = ({ sites, ...chakraProps }: Props) => {
  const { ts } = useTranslation();

  if (!sites?.length) return null;

  const sortedSites = sortAlphabetical(sites, (site) => ts(site.name) ?? "N/A");

  return (
    <Grid
      as="ul"
      gridTemplateColumns={{ base: "1fr", md: "1fr 1fr" }}
      gap="1rem 4rem"
      listStyleType="none"
      {...chakraProps}
    >
      {sortedSites.map((site) => (
        <Box as="li" key={site._id}>
          <HStack
            as="a"
            width="fit-content"
            minHeight="3.5rem"
            padding=".5rem .75rem"
            borderRadius="0.25rem"
            transition="background 0.15s ease"
            href={getSiteUrl(site)}
            textDecoration="none"
            justifyContent="flex-start"
            alignItems="center"
            gap=".75rem"
            _hover={{ background: colors.grey05, textDecoration: "underline" }}
          >
            <SanityImagePreservingAspectRatio
              boxShadow="none"
              borderRadius="none"
              image={site.municipality?.coatOfArms}
              resolution={200}
              width="1.9rem"
              alt=""
            />
            <Heading fontWeight="400" as="h3" fontSize="md">
              {ts(site.name)}
            </Heading>
          </HStack>
        </Box>
      ))}
    </Grid>
  );
};
